import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import classNames from 'classnames';

import { noOpFn } from 'Utils/no-op-fn';

import withT from 'ComponentLibrary/hocs/withT';
import { Meatball } from './meatball';

export const MeatballButton = styled.button(({ highlight }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  height: '36px',
  width: '36px',
  cursor: 'pointer',
  pointerEvents: 'auto',
  backgroundColor: `${
    highlight ? ICON_COLORS.emphasisBackground : 'transparent'
  }`,
  '&:focus': { [Meatball]: { backgroundColor: ICON_COLORS.base } },
}));

function MeatballsMenuIcon({ className, toolTip, onClick, highlight, t }) {
  return (
    <MeatballButton
      className={classNames('button--base', className)}
      type="button"
      highlight={highlight}
      onClick={onClick}
      title={toolTip || t('menu')}
    >
      <Meatball
        className="meatballs-menu-icon__meatball"
        highlight={highlight}
      />
      <Meatball
        className="meatballs-menu-icon__meatball"
        highlight={highlight}
      />
      <Meatball
        className="meatballs-menu-icon__meatball"
        highlight={highlight}
      />
    </MeatballButton>
  );
}

MeatballsMenuIcon.propTypes = {
  className: PropTypes.string,
  toolTip: PropTypes.string,
  onClick: PropTypes.func,
  highlight: PropTypes.bool,
  color: PropTypes.string,
  t: PropTypes.func.isRequired,
};

MeatballsMenuIcon.defaultProps = {
  className: '',
  highlight: false,
  color: null,
  onClick: noOpFn,
};

export default withT(MeatballsMenuIcon);
