/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import WindowResize from 'Utils/window-resize';

// Components
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';
import RefWrapper from 'ComponentLibrary/utility/ref-wrapper';

/**
 * This component handles the rendering of a TooltipMenu, as well as it's toggle Element.
 * Note that only TooltipMenuItem components are allowed as children for this component.
 * @param {React Element} toggleElement - The component that opens the Menu when clicked.
 * @param {String} positionX - Chooses which x direction to align the tooltip menu.
 * @param {String} positionY - Chooses which y direction to align the tooltip menu.
 * @param {React Element <TooltipMenuItem>} children - 0 to many TooltipMenuItem
 */

// Exported and empty so that it can be used as a child selector in other styled classes.
export const StyledTooltipAnchoredDialog = styled(AnchoredDialog)();

class TooltipMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleViewportChange = this.handleViewportChange.bind(this);

    this.resizer = new WindowResize(this.handleViewportChange);

    this.menuElement = React.createRef();
  }

  open() {
    this.setState({ isOpen: true });
    this.resizer.subscribe();
  }

  close() {
    this.setState({ isOpen: false });
    this.resizer.unsubscribe();
  }

  handleViewportChange() {
    this.state.isOpen && this.close();
  }

  handleClickOutside() {
    if (!this.state.isOpen) {
      return;
    }
    this.close();
  }

  handleToggleMenu(e) {
    e.stopPropagation();
    this.state.isOpen ? this.close() : this.open();
  }

  hideClick = () => {
    this.close();
  };

  render() {
    const {
      fixed,
      toggleElement,
      children,
      className,
      anchoredDialogStyleVariation,
      hideAfterClicking,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <RefWrapper
        ref={this.menuElement}
        className="anchored-dialog-menu__ref-wrapper"
      >
        <div className="anchored-dialog-menu__wrapper">
          <div onClick={this.handleToggleMenu}>{toggleElement}</div>
          {isOpen && (
            <StyledTooltipAnchoredDialog
              className={className}
              targetRef={this.menuElement}
              fixed={fixed}
              styleVariation={anchoredDialogStyleVariation}
              onResize={this.handleClickOutside}
              onClickOutside={this.handleClickOutside}
              onClick={hideAfterClicking ? this.hideClick : null}
            >
              {children}
            </StyledTooltipAnchoredDialog>
          )}
        </div>
      </RefWrapper>
    );
  }
}

TooltipMenu.propTypes = {
  fixed: PropTypes.bool,
  toggleElement: PropTypes.element.isRequired,
  className: PropTypes.string,
  anchoredDialogStyleVariation: PropTypes.oneOf(
    Object.values(AnchoredDialog.styleVariations),
  ),
  children: PropTypes.node.isRequired,
  hideAfterClicking: PropTypes.bool,
};

TooltipMenu.defaultProps = {
  className: '',
  anchoredDialogStyleVariation: AnchoredDialog.styleVariations.MENU,
};

export default TooltipMenu;
