import React from 'react';

// This component's sole purpose is to provide a target
// for a ref without interfering with HOCs
const RefWrapper = React.forwardRef((props, ref) => (
  <div {...props} className="ref-wrapper" ref={ref} />
));

RefWrapper.displayName = 'RefWrapper';

export default RefWrapper;
